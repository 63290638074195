<template>
  <div class="hello">
    <div class="title">
        <div class="intro">
            <h1 class="tth1"><span style="font-weight: 200;">Glowstone </span>萤石</h1>
            <h3>{{ msg }}</h3>
        </div>
    </div>
    <div class="myself">
        <div class="intro">
            <h1 class="msh1">关于我</h1>
            <h3>从小学<span>四年级</span>开始学习 <span>Java</span></h3>
            <h3>拥有<span>六年</span>Java编码经验，正在学习<span>Kotlin, Rust, TypeScript</span>和<span>Functional Programming</span>，程序的最终目标是以<span>简单，优雅</span>的方式高效处理问题。</h3>
            <h3>技术栈：</h3>
            <img src="https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=openjdk&logoColor=white">
            <img src="https://img.shields.io/badge/Kotlin-0095D5?&style=for-the-badge&logo=kotlin&logoColor=white">
            <img src="https://img.shields.io/badge/Vue.js-35495E?style=for-the-badge&logo=vue.js&logoColor=4FC08D">
            <img src="https://img.shields.io/badge/Spring-6DB33F?style=for-the-badge&logo=spring&logoColor=white">
            <img src="https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white">
            <img src="https://img.shields.io/badge/Node.js-43853D?style=for-the-badge&logo=node.js&logoColor=white">
			<img src="https://img.shields.io/badge/Rust-orange?style=for-the-badge&logo=rust">
        </div>
		<div class="intro">
			<h1 class="msh1">开发习惯</h1>
			<h2>开发工具</h2>
			<h3><span>MacBook Pro(M3max 10P+4E, 16inch)</span></h3>
			<h3><span>i9-13900K 缩肛版全核4.8Ghz + 128G (4x32G) D4 3200MHz + Rocky Linux 9.4</span></h3>
			<h2>Linux 偏好：</h2>
			<h3><span>Debian = Rocky Linux > Ubuntu > CentOS > Arch > ....</span>(其中只有RockyLinux与Debian被运用于生产环境)</h3>
			<h2>编程语言熟练度排行</h2>
			<h3><span>Java</span> (>= 2w行)</h3>
			<h3><span>Kotlin</span>(5k-6k行，仅JVM)</h3>
			<h3><span>JavaScript</span>(1k-2k行)</h3>
			<h3><span>Rust</span>(900-1000行)</h3>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.intro {
	margin-bottom: 6vh;
}
h2 {
	font-weight: 100;
	color: #d1cfcf;
}
@import "@/css/main.css";
</style>
